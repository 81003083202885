import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["campSelect", "pricePointSelect"];

  connect() {
  }

  updatePricePoints() {
    const campId = this.campSelectTarget.value;
    fetch(`/admin/camps/${campId}/price_points`)
      .then(response => response.json())
      .then(data => this.populatePricePoints(data));
  }

  populatePricePoints(pricePoints) {
    const options = pricePoints.map(pp => `<option value="${pp.id}">${pp.title}</option>`);
    this.pricePointSelectTarget.innerHTML = options.join("");
  }
}

