import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["table"];

  connect() {
    if (!this.hasTableTarget) return;
    
    $(this.tableTarget).DataTable({
      responsive: true,
      retrieve: true,
      autoWidth: false,
      processing: true,
      pageLength: 50,
      "pageLength": -1,
      order: [[2, "asc"]],
      lengthMenu: [
        [10, 25, 50, 100, -1],
        ['10 rows', '25 rows', '50 rows', '100 rows', "All"],
      ],
      language: {
        search: '<i class="fa fa-search"></i>',
        searchPlaceholder: "SEARCH",
      },
    });
  }
}
