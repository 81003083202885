import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="password-match"
export default class extends Controller {
  static targets = ["password", "confirmation"];
  check() {
    let passwordMatchMessage = document.getElementById("password-match-message");
    if (this.passwordTarget.value == this.confirmationTarget.value && this.passwordTarget.value){
      passwordMatchMessage.innerHTML = "<i class='fa fa-check-circle active-icon'></i><span>Passwords match!</span>"
    } else {
      passwordMatchMessage.innerHTML = "<i class='fa fa-times-circle inactive-icon'></i><span>Passwords are not matching...</span>"
    }

  }
}
